<template>
    <section class="swiper-container">
        <Swiper
            style="height: 100%"
            :dir="rtl"
            :slidesPerGroup="4"
            :modules="[SwiperAutoplay, SwiperNavigation, SwiperPagination]"
            :autoplay="{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }"
            spaceBetween="15"
            :breakpoints="{
                '0': {
                    slidesPerView: 1.2,
                    slidesPerGroup: 1,
                },
                '400': {
                    slidesPerView: 1.4,
                    slidesPerGroup: 1,
                },
                '500': {
                    slidesPerView: 1.6,
                    slidesPerGroup: 1,
                },
                '767': {
                    slidesPerView: 2,
                    slidesPerGroup: 1,
                },
                '992': {
                    slidesPerView: 2.5,
                    slidesPerGroup: 1,
                },
                '1200': {
                    slidesPerView: 3.5,
                    slidesPerGroup: 1,
                },
                '1280': {
                    slidesPerView: 4,
                },
            }"
        >
            <swiper-slide v-for="course in data" :key="course.id">
                <CourseCard
                    :id="course?.id"
                    :courseTitle="course?.name"
                    :logo="
                        category === 'university'
                            ? course?.includes?.university?.logo
                            : course?.includes?.institute?.logo
                    "
                    :categoryID="
                        category === 'university' ? course?.includes?.university?.id : course?.includes?.institute?.id
                    "
                    :categoryTitle="
                        category === 'university'
                            ? course?.includes?.university?.name
                            : course?.includes?.institute?.name
                    "
                    :location="`${course?.includes?.country?.name}, ${course?.includes?.city?.name || ''}`"
                    :fees="course?.fees"
                    :currency="course?.includes?.currency?.name"
                    :date="
                        category === 'university'
                            ? course?.includes?.dates.map(arr => arr.months.join(', ')).join(', ')
                            : ''
                    "
                    :favourite="course?.is_favorite"
                />
            </swiper-slide>
        </Swiper>
    </section>
</template>

<script setup>
import CourseCard from './CourseCard.vue';
import { useCommonStore } from '../../../store/search/common';

const commonStore = useCommonStore();
const { category } = storeToRefs(commonStore);

const props = defineProps({
    data: {
        type: Array,
        default: [],
    },
});
</script>

<style lang="scss" scoped>
@import 'libs/shared/assets/scss/main.scss';
.swiper-container {
    margin-bottom: toRem(20);
    .swiper {
        padding: 0 0.625rem 0.625rem;
        .swiper-slide {
            height: auto;
        }
    }
}
</style>
