<template>
    <div class="main-searchbar-container">
        <form @submit.prevent="search">
            <input v-model="searchKeyword" class="search" type="text" :placeholder="$t('search.searchText')" />
            <button class="submit" type="submit" :class="{ 'display-none': !searchBtn }">
                <i class="icomoon icon-search"></i>
            </button>
        </form>
        <div v-if="allFilterValues.length" class="tags-container">
            <Tag
                v-for="tag in allFilterValues"
                :text="tag.text"
                :key="tag.id"
                :regular="false"
                :active="true"
                :closeIcon="true"
                :action="() => removeItem(tag.id, tag.type)"
            />
        </div>
    </div>
</template>

<script setup>
import { useCheckCategory } from '../../../composables/useCheckCategory';
import { useCommonStore } from '../../../store/search/common';
import Tag from '../../general/Tag.vue';
const {
    getCategoryList,
    getCategoryCourses,
    categorySearchKeyword,
    allFilterValues,
    categoryFilterCountries,
    categoryFilterUniversitiesOrInstitutes,
    categoryFilterSubjects,
    categoryFilterLevels,
    categoryFilterIntakeDates,
    categoryFilterPriceRange,
} = useCheckCategory();

const commonStore = useCommonStore();
const { preventClear } = storeToRefs(commonStore);
const props = defineProps({
    searchBtn: {
        type: Boolean,
        default: true,
    },
});

const searchKeyword = ref('');

onMounted(async () => {
    await nextTick();
    if (preventClear.value) {
        searchKeyword.value = categorySearchKeyword.value.value ? categorySearchKeyword.value.value : '';
    }
});

const search = _ => {
    if (categorySearchKeyword.value.value !== searchKeyword.value) {
        categorySearchKeyword.value.value = searchKeyword.value;
        getCategoryList.value();
        getCategoryCourses.value();
    }
};

const removeItem = (id, type) => {
    const typeMapping = {
        country: categoryFilterCountries.value,
        category: categoryFilterUniversitiesOrInstitutes.value,
        subject: categoryFilterSubjects.value,
        date: categoryFilterIntakeDates.value,
        level: categoryFilterLevels.value,
        price: categoryFilterPriceRange.value,
    };

    const targetArray = typeMapping[type];

    if (targetArray) {
        const index = targetArray.findIndex(item => item.id === id);

        if (index !== -1) {
            targetArray.splice(index, 1);
        }
    }

    // Call APIs
    getCategoryList.value();
    getCategoryCourses.value();
};
</script>

<style lang="scss" scoped>
@import 'libs/shared/assets/scss/main.scss';
.main-searchbar-container {
    width: 100%;
    form {
        display: flex;
        background-color: white;
        border: 1px solid #0000001f;
        border-radius: toRem(10);
        padding: toRem(6);
        width: 100%;
        min-height: toRem(60);
        .search {
            width: 100%;
            border: none;
            padding: 0 toRem(10);
            outline: none;
        }
        .submit {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: toRem(10);
            background-color: $orange;
            box-shadow: 0px 4px 8px 0px #f2502926;
            border: none;
            outline: none;
            padding: toRem(12) toRem(18);
            i {
                font-size: toRem(20);
                color: white;
            }
        }
    }
    .tags-container {
        margin-top: toRem(10);
        display: flex;
        gap: toRem(16);
        align-items: center;
        flex-wrap: wrap;
    }
}
@include mediaMin($lg) {
    .main-searchbar-container {
        width: 70%;
        form {
            .submit {
                padding: toRem(15) toRem(45);
            }
        }
    }
}
</style>
