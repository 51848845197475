<template>
    <Toast />
    <div class="category-container">
        <LoadingDropShadow v-if="loading" />
        <div class="container">
            <BackButton :home="true" :title="$t('searchresults')" />

            <!-- Category Tabs -->
            <CategoryTabs />

            <!-- Search Bar-->
            <section class="search-section">
                <FilterButton class="filter-btn" @click="filterVisibility = true" :totalFilters="totalFilters" />
                <MainSearchBar class="search-bar" :searchBtn="true" />
                <Compare v-if="projectName === 'b2c' && activeTab === 3 && category === 'university'" class="compare" />
            </section>

            <InlineMessage v-if="compareActive" class="w-75 alert-item compare-message" severity="info">
                {{ $t('compare3') }}
            </InlineMessage>

            <hr class="h-line" />

            <!-- Listing Tabs -->
            <ListingTabs />

            <!-- Filter -->
            <Filter />

            <div
                :class="
                    compareActive && compareList.length > 0
                        ? 'filter-btn-responsive-with-compare'
                        : 'filter-btn-responsive'
                "
            >
                <FilterButton @click="filterVisibility = true" />
            </div>

            <CompareList />
        </div>
    </div>
</template>

<script setup>
import FilterButton from '../common/FilterButton.vue';
import MainSearchBar from '../common/MainSearchBar.vue';
import BackButton from '../../general/BackButton.vue';
import CategoryTabs from '../common/CategoryTabs.vue';
import Compare from '../common/Compare.vue';
import ListingTabs from './ListingTabs.vue';
import LoadingDropShadow from '../common/LoadingDropShadow.vue';
import Filter from '../common/Filter/index.vue';
import CompareList from '../common/CompareList.vue';
import { useLevelsStore } from '../../../store/levels';

import { useCommonStore } from '../../../store/search/common';
import { useCheckCategory } from '../../../composables/useCheckCategory.js';

const levelsStore = useLevelsStore();

const commonStore = useCommonStore();
const { activeTab, loading, filterVisibility, projectName, preventClear, category, compareActive, compareList } =
    storeToRefs(commonStore);
const { totalFilters, categoryFilterClear, getCategoryList, getCategoryCourses } = useCheckCategory();

onMounted(async () => {
    await nextTick();
    if (!preventClear.value) {
        commonStore.clearCompare();
        categoryFilterClear.value();
        activeTab.value = 1;
    }
    preventClear.value = false;
    getCategoryList.value();
    getCategoryCourses.value();
    levelsStore.getLevels(category.value === 'university' ? 'type[]=university_course' : 'type[]=english_course');
});
</script>

<style lang="scss" scoped>
@import 'libs/shared/assets/scss/main.scss';
.category-container {
    .search-section {
        display: flex;
        margin-bottom: toRem(24);
        flex-direction: column;
        align-items: flex-start;
        .search-bar {
            margin: 0 auto;
        }
        .filter-btn {
            display: none;
        }
        .compare {
            margin-top: toRem(24);
        }
    }

    .compare-message {
        margin-bottom: toRem(24);
    }

    .h-line {
        border-color: $gray-11;
        opacity: 1;
    }
    .filter-btn-responsive {
        position: fixed;
        bottom: 3%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10000;
    }

    .filter-btn-responsive-with-compare {
        position: fixed;
        bottom: 45%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10000;
    }
}
@include mediaMin($lg) {
    .category-container {
        .search-section {
            margin-bottom: toRem(80);
            flex-direction: row;
            .filter-btn {
                display: flex;
            }
            .compare {
                margin-top: 0;
            }
        }
        .h-line {
            display: none;
        }
        .filter-btn-responsive {
            display: none;
        }
        .filter-btn-responsive-with-compare {
            display: none;
        }
    }
}
</style>
