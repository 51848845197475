<template>
    <div class="category-card-container">
        <div class="card-container">
            <div class="img-container">
                <img :src="imgURL" :alt="cardTitle" :title="cardTitle" />
            </div>
            <div class="content-container">
                <NuxtLink @click="categoryDetailsRedirect">
                    <h3 class="title" :title="cardTitle">{{ cardTitle }}</h3>
                </NuxtLink>
                <div class="location-container">
                    <img src="../../../assets/images/search/location.svg" alt="Location Icon" />
                    <span class="location" :title="location">{{ location }}</span>
                </div>
                <CardButton class="action-btn" :text="btnText" @click="categoryDetailsRedirect" />
            </div>
        </div>
        <hr class="h-line" />
        <CardButton class="action-btn-responsive" :text="btnText" @click="categoryDetailsRedirect" />
    </div>
</template>

<script setup>
import { useCheckCategory } from '../../../composables/useCheckCategory';
import CardButton from './CardButton.vue';

const props = defineProps({
    imgURL: {
        type: String,
        required: false,
        default: '',
    },
    cardTitle: {
        type: String,
        required: false,
        default: '',
    },
    location: {
        type: String,
        required: false,
        default: '',
    },
    btnText: {
        type: String,
        required: false,
        default: 'Viewdetails',
    },
    link: {
        type: String,
        required: false,
        default: '',
    },
});
//check if we are searching for courses from a student profile in B2B
const route = useRoute();
const studentID = route.query.studentID ? route.query.studentID : null;

const finalLink = computed(() => {
    return studentID !== null ? props.link + '?studentID=' + studentID : props.link;
});

const { resetCategorySearchKeyword, categoryCoursesTotal } = useCheckCategory();

const router = useRouter();
const categoryDetailsRedirect = () => {
    if (categoryCoursesTotal.value === 0) {
        resetCategorySearchKeyword();
    }
    router.push(finalLink.value);
};
</script>

<style lang="scss" scoped>
@import 'libs/shared/assets/scss/main.scss';

.category-card-container {
    padding: toRem(16) toRem(14);
    box-shadow: 0px 4px 8px 0px #0000001a;
    border: 1px solid $lighter-gray;
    border-radius: toRem(10);
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: toRem(16);

    .card-container {
        display: flex;
        justify-content: center;
        gap: toRem(16);
        width: 100%;
        .img-container {
            width: 40%;
            border: 1px solid $gray-4;
            padding: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: toRem(10);
            height: toRem(90);
            img {
                max-width: 90%;
                max-height: 100%;
            }
        }
        .content-container {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            gap: toRem(16);
            .title {
                cursor: pointer;
                margin-top: 0;
                color: $dark-gray;
                font-size: toRem(16);
                font-weight: 600;
                @include lines(2);
            }
            .location-container {
                display: flex;
                align-items: center;
                gap: toRem(8);
                img {
                    width: toRem(11);
                }
                .location {
                    color: $dim-gray;
                    font-weight: 600;
                    font-size: toRem(12);
                    @include lines(1);
                }
            }
        }
    }
    .h-line {
        border-color: $gray-6;
        opacity: 1;
        margin: 0;
    }
    .action-btn {
        display: none;
    }
}
@include mediaMin($lg) {
    .category-card-container {
        .card-container {
            .img-container {
                height: 160px;
            }
            .content-container {
                justify-content: space-between;
                .title {
                    margin-top: toRem(5);
                    font-size: toRem(18);
                }
            }
        }
        .action-btn {
            display: flex;
        }
        .h-line,
        .action-btn-responsive {
            display: none;
        }
    }
}
</style>

