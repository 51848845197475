<template>
    <div class="price-range-filter">
        <div class="filter-header">
            <h3>{{ $t('advSearch.filter.price.priceRange') }}</h3>
            <span class="reset-filter" @click="resetFilter">
                {{ $t('reset') }}
            </span>
        </div>
        <div class="slider-container">
            <Slider
                v-model="priceRange"
                :min="courseFeesObj?.min_fees"
                :max="courseFeesObj?.max_fees"
                :step="1"
                showTooltip="drag"
                :direction="$i18n.locale === 'ar' ? 'rtl' : 'ltr'"
                class="price-slider"
                @update="handleSliderUpdate"
            />
            <div class="price-inputs">
                <div class="price-input">
                    <label>{{ $t('advSearch.filter.price.minPrice') }}</label>
                    <InputNumber
                        v-model="priceRange[0]"
                        :min="courseFeesObj?.min_fees"
                        :max="courseFeesObj?.max_fees"
                        class="price-input-field"
                        @update:modelValue="updateSelectedItems"
                    />
                </div>
                <div class="price-input">
                    <label>{{ $t('advSearch.filter.price.maxPrice') }}</label>
                    <InputNumber
                        v-model="priceRange[1]"
                        :min="courseFeesObj?.min_fees"
                        :max="courseFeesObj?.max_fees"
                        class="price-input-field"
                        @update:modelValue="updateSelectedItems"
                    />
                </div>
            </div>
            <span class="validation-message" v-if="priceRange[0] > priceRange[1]">
                {{ $t('advSearch.filter.price.validationMessage') }}
            </span>
        </div>
    </div>
</template>

<script setup>
import { useCheckCategory } from '../../../../composables/useCheckCategory';
import Slider from '@vueform/slider';
const { courseFeesObj, categoryFilterPriceRange } = useCheckCategory();
const { t } = useI18n();

const intialPrice = [courseFeesObj.value?.min_fees, courseFeesObj.value?.max_fees];
const priceRange = ref([]);
const emit = defineEmits(['update:selectedItems']);
const selectedItems = ref([...categoryFilterPriceRange.value]);

onMounted(() => {
    updateSelectedItems();
});

if (categoryFilterPriceRange.value[0]?.priceRange?.length) {
    priceRange.value = [...categoryFilterPriceRange.value[0].priceRange];
} else {
    priceRange.value = [...intialPrice];
}

const updateSelectedItems = async () => {
    if (priceRange.value[0] == intialPrice[0] && priceRange.value[1] == intialPrice[1]) {
        selectedItems.value = [];
    } else {
        selectedItems.value = [
            {
                id: 1,
                text: `${t('advSearch.filter.price.priceFromTo', {
                    min: priceRange.value[0],
                    max: priceRange.value[1],
                })}`,
                type: 'price',
                priceRange: priceRange.value,
            },
        ];
    }
    emit('update:selectedItems', selectedItems.value);
};

const resetFilter = () => {
    priceRange.value = [courseFeesObj.value?.min_fees, courseFeesObj.value?.max_fees];
    updateSelectedItems();
};

const handleSliderUpdate = value => {
    priceRange.value = value.map(Math.round);
    updateSelectedItems();
};
</script>

<style lang="scss" scoped>
@import 'libs/shared/assets/scss/main.scss';
.price-range-filter {
    .filter-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        h3 {
            margin-bottom: 0;
            color: $dark-gray;
            font-size: toRem(16);
        }
        .reset-filter {
            cursor: pointer;
            color: $primary-color;
            font-size: toRem(12);
        }
    }

    .slider-container {
        padding: 0 0.5rem;

        .price-slider {
            margin: 1.5rem 0;
            :deep(.slider-connects .slider-connect) {
                background-color: $primary-color;
            }
            :deep(.slider-origin) {
                .slider-handle {
                    border: 3px solid $primary-color;
                }
                .slider-tooltip.slider-tooltip-top {
                    background-color: $primary-color;
                    border-color: $primary-color;
                }
            }
        }

        .price-inputs {
            display: flex;
            justify-content: space-between;

            .price-input {
                width: 48%;
                label {
                    display: block;
                    margin-bottom: 0.5rem;
                    color: $dim-gray;
                    font-size: toRem(12);
                }

                .price-input-field {
                    width: 100%;
                    :deep(.p-inputnumber-input) {
                        text-align: center;
                        width: 100%;
                    }
                }
            }
        }
    }
    .validation-message {
        color: $orange;
        font-size: toRem(12);
        width: 100%;
        margin-top: 0.5rem;
    }
}
</style>
