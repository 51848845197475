<template>
    <div class="box-container">
        <h3 class="label">{{ $t('country') }}</h3>
        <div class="filter-box">
            <AutoComplete
                v-model="topicModel"
                :placeholder="$t('advSearch.filter.chooseCountry')"
                :suggestions="topicsItems"
                dropdown
                field="name"
                @complete="topicSearch"
                @item-select="onTopicSelected"
            >
                <template #empty>
                    <span>{{ $t('advSearch.filter.noDataFound') }}</span>
                </template>
            </AutoComplete>
        </div>
        <div v-if="selectedItems.length" class="tags-container">
            <Tag
                v-for="tag in selectedItems"
                :key="tag.id"
                :text="tag.text"
                :closeIcon="true"
                :regular="false"
                :active="true"
                :action="() => removeTag(tag.id)"
            />
        </div>
    </div>
</template>

<script setup>
import debounce from 'lodash/debounce';
import Tag from '../../../general/Tag.vue';
import { useDestinationsStore } from '../../../../store/destinations';
import { useCheckCategory } from '../../../../composables/useCheckCategory';

const { categoryFilterCountries, getAllCategory } = useCheckCategory();
const destinationsStore = useDestinationsStore();

const { destinations } = storeToRefs(destinationsStore);
const topicModel = ref('');
const topicsItems = ref([]);
const emit = defineEmits(['update:selectedItems']);
const selectedItems = ref([...categoryFilterCountries.value]);

onMounted(() => {
    updateSelectedItems();
});

// Emit updated selected items
const updateSelectedItems = async () => {
    emit('update:selectedItems', selectedItems.value);

    //Get all category depends on selected country for filter
    await getAllCategory.value(selectedItems.value);
};

// 2 seconds debounce delay
const debouncedSearch = debounce(async query => {
    topicsItems.value = await destinationsStore.getDestinations(query.toLowerCase());
}, 2000); //

// Search topics on autocomplete input
const topicSearch = async event => {
    if (event.query) {
        debouncedSearch(event.query);
    } else {
        topicsItems.value = [...destinations.value];
    }
};

// Handle selecting a topic
const onTopicSelected = item => {
    if (!selectedItems.value.some(selected => selected.id === item.value.id)) {
        selectedItems.value.push({ id: item.value.id, text: item.value.name, type: 'country' });
    }
    updateSelectedItems();
    topicModel.value = '';
};

// Remove tag from selected items
const removeTag = id => {
    selectedItems.value = selectedItems.value.filter(tag => tag.id !== id);
    updateSelectedItems();
};
</script>

<style lang="scss">
@import 'libs/shared/assets/scss/main.scss';
.box-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .label {
        font-size: toRem(16);
        color: $dark-gray;
    }
    .filter-box {
        border: 1px solid $gray-9;
        border-radius: toRem(10);

        .p-autocomplete.p-component {
            width: 100%;
            border: none;
            outline: none;

            .p-autocomplete-input.p-inputtext.p-component {
                border: none;
                border-radius: 10px;
                outline: none;
                box-shadow: none;
            }
        }
    }
    .tags-container {
        display: flex;
        flex-wrap: wrap;
        gap: 0.75rem 0.5rem;
    }
}
</style>
