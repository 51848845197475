<template>
    <Category />
</template>

<script setup>
import Category from '../../../libs/shared/components/search/Category/index.vue';
import { useUniversityStore } from '../../../../libs/shared/store/search/university';
import { useInstituteStore } from '../../../../libs/shared/store/search/institute';
import { useCommonStore } from '../../../../libs/shared/store/search/common';
import { useCountriesStore } from '../../../../libs/shared/store/countries';
import { useCheckCategory } from '../../../../libs/shared/composables/useCheckCategory';

const route = useRoute();
const commonStore = useCommonStore();
const { category } = storeToRefs(commonStore);
const countryStore = useCountriesStore();
const { countries } = storeToRefs(countryStore);
const { allCategory, getAllCategory } = useCheckCategory();

onMounted(async () => {
    await nextTick();
    if (countries.value.length === 0) await countryStore.getCountries(); //Get all countries for filter
    if (allCategory.value.length === 0) await getAllCategory.value(); //Get all category for filter
});

watchEffect(() => {
    if (route.params.category === 'university') {
        useUniversityStore();
        category.value = 'university';
    } else {
        useInstituteStore();
        category.value = 'institute';
    }
});
</script>
