// import flags from '../src/countries.json';

export const useDestinationsStore = defineStore('destinations', () => {
    const { $getApi } = useNuxtApp();

    const destinations = ref([]);
    const loading = ref(true);

    // const checkISOCode = list => {
    //     return list
    //         .filter(resItem => Boolean(resItem.iso_code))
    //         .map(resItem => {
    //             const country = flags?.data?.find(c => c.iso_code === resItem.iso_code);
    //             if (!country) return null;

    //             const show = country.flag || country.emoji || '';
    //             return {
    //                 id: resItem.id,
    //                 show,
    //                 code: resItem.code ?? '',
    //                 flag: country.flag ?? '',
    //                 name: resItem.name ?? '',
    //                 numberLength: resItem.number_length ?? 0,
    //             };
    //         })
    //         .filter(Boolean);
    // };

    const getDestinations = async (keyword = '') => {
        let query = `countries?is_map=1&per_page=10`;
        if (keyword) {
            query += `&filter[name]=${keyword}`;
        }

        try {
            const res = await $getApi(query);

            if (keyword) {
                let value;
                value = res.data.data;
                // value = checkISOCode(value);
                return value;
            } else {
                destinations.value = res.data?.data || [];
                // destinations.value = checkISOCode(destinations.value);
            }
        } catch (error) {
            console.error('Error fetching university subjects:', error);
        } finally {
            loading.value = false;
        }
    };

    getDestinations();

    return { destinations, loading, getDestinations };
});
