<template>
    <section class="listing-tabs">
        <Tag
            :text="`${t('advSearch.allResults')} ${categoriesAndCoursesTotal}`"
            :active="activeTab === 1"
            :clickable="true"
            @click="setActiveTab(1)"
        />
        <Tag
            :text="`${sectionTitle} ${categoryListTotal}`"
            :active="activeTab === 2"
            :clickable="true"
            @click="setActiveTab(2)"
        />
        <Tag
            :text="`${t('courses')} ${categoryCoursesTotal}`"
            :active="activeTab === 3"
            :clickable="true"
            @click="setActiveTab(3)"
        />
    </section>
    <section>
        <Tab1 v-if="activeTab === 1" />
        <Tab2 v-if="activeTab === 2" />
        <Tab3 v-if="activeTab === 3" />
    </section>
</template>

<script setup>
const { t } = useI18n();
import Tag from '../../general/Tag.vue';
import Tab1 from './Tab1.vue';
import Tab2 from './Tab2.vue';
import Tab3 from './Tab3.vue';
import { useCommonStore } from '../../../store/search/common';
import { useCheckCategory } from '../../../composables/useCheckCategory';

const commonStore = useCommonStore();
const { activeTab, compareActive } = storeToRefs(commonStore);
const { sectionTitle, categoryListTotal, categoryCoursesTotal, categoriesAndCoursesTotal } = useCheckCategory();

const setActiveTab = id => {
    activeTab.value = id;
    compareActive.value = false;
};
</script>

<style lang="scss" scoped>
@import 'libs/shared/assets/scss/main.scss';

.listing-tabs {
    display: flex;
    flex-wrap: wrap;
    gap: toRem(10);
    margin-bottom: toRem(40);
}
@include mediaMin($lg) {
    .listing-tabs {
        gap: toRem(24);
        margin-bottom: toRem(48);
    }
}
</style>
