<template>
    <section class="tab2-container">
        <!-- Category Listing -->
        <div class="listing-container">
            <div class="section-header">
                <SectionTitle
                    class="section-title"
                    :icon="universityImg"
                    :title="sectionTitle"
                    :total="categoryListTotal"
                />
                <!-- TODO: Hide for now until a business requirement is added -->
                <!-- <Sort isCategory /> -->
            </div>
            <div class="listing-category-grid" v-if="categoryList.length">
                <CategoryCard
                    v-for="item in categoryList"
                    :key="item.id"
                    :imgURL="item?.logo"
                    :cardTitle="item?.name"
                    :location="`${item?.includes?.country?.name}, ${item?.includes?.city?.name || ''}`"
                    :link="`/${category}/${item?.id}`"
                />
            </div>
            <NoDataFound v-else />
            <div v-if="categoryLoading" class="loader-container">
                <ProgressSpinner
                    style="width: 50px; height: 50px"
                    strokeWidth="8"
                    fill="transparent"
                    animationDuration=".5s"
                />
            </div>
        </div>
    </section>
</template>

<script setup>
import universityImg from '../../../../libs/shared/assets/images/search/university.png';
import CategoryCard from '../common/CategoryCard.vue';
import SectionTitle from '../common/SectionTitle.vue';
import Sort from '../common/Sort.vue';
import { useCheckCategory } from '../../../composables/useCheckCategory';
import NoDataFound from '../common/NoDataFound.vue';
import { useCommonStore } from '../../../store/search/common';
const commonStore = useCommonStore();
const { category } = storeToRefs(commonStore);

const {
    sectionTitle,
    categoryListTotal,
    categoryList,
    categoryLoading,
    categoryCurrentPage,
    categoryLastPage,
    getCategoryList,
} = useCheckCategory();

// Detect when the user scrolls to the bottom
const onScroll = () => {
    // Start loading 500px before bottom
    const scrollBuffer = 500;
    const nearBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - scrollBuffer;

    if (nearBottom && !categoryLoading.value && categoryCurrentPage.value < categoryLastPage.value) {
        getCategoryList.value(categoryCurrentPage.value + 1, true);
    }
};

// Set up the scroll listener
onMounted(() => {
    window.addEventListener('scroll', onScroll);
});

// Clean up the scroll listener when the component is destroyed
onBeforeUnmount(() => {
    window.removeEventListener('scroll', onScroll);
});
</script>

<style lang="scss" scoped>
@import 'libs/shared/assets/scss/main.scss';
.tab2-container {
    .listing-container {
        .section-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: toRem(24);
            .section-title {
                margin: 0;
            }
        }
        .loader-container {
            margin-top: 3rem;
            display: flex;
            justify-content: center;
        }
    }
}
@include mediaMin($lg) {
    .tab2-container {
        .listing-container {
            .section-header {
                margin-bottom: toRem(40);
            }
        }
        .filter-btn-responsive {
            display: none;
        }
    }
}
.listing-category-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: toRem(16);

    @include respond-to(laptop) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to(tablet) {
        grid-template-columns: repeat(1, 1fr);
    }
}
</style>
