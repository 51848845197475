<template>
    <section class="category-tabs">
        <NuxtLink :to="universityLink">
            <button class="btn" :class="{ active: category === 'university' }" @click="activeTab = 1">
                {{ $t('university') }}
            </button>
        </NuxtLink>
        <NuxtLink :to="instituteLink">
            <button class="btn" :class="{ active: category !== 'university' }" @click="activeTab = 1">
                {{ $t('advSearch.englishschools') }}
            </button>
        </NuxtLink>
    </section>
</template>

<script setup>
import { useCommonStore } from '../../../store/search/common';

const commonStore = useCommonStore();
const { category, activeTab } = storeToRefs(commonStore);

//check if we are searching for courses from a student profile in B2B
const route = useRoute();
const studentID = route.query.studentID ? route.query.studentID : null;
const universityLink = computed(() => {
    return studentID !== null ? `/university?studentID=${studentID}` : `/university`;
});
const instituteLink = computed(() => {
    return studentID !== null ? `/institute?studentID=${studentID}` : `/institute`;
});
</script>

<style lang="scss" scoped>
@import 'libs/shared/assets/scss/main.scss';

.category-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: toRem(9);
    margin-bottom: toRem(25);
    .btn {
        font-size: toRem(14);
        font-weight: 400;
        color: $primary-color;
        padding: toRem(13) toRem(8);
        width: toRem(130);
        background-color: white;
        border: 1px solid $gray-8;
        border-radius: toRem(10);
        outline: none;
        box-shadow: 0px 4px 10px 0px #00000026;
        &.active {
            font-weight: 700;
            background-color: $primary-color;
            color: white;
            box-shadow: 0px 4px 10px 0px #0369a140;
            border-color: $primary-color;
        }
    }
}
@include mediaMin($md) {
    .category-tabs {
        gap: toRem(16);
        .btn {
            font-size: toRem(16);
            width: toRem(220);
        }
    }
}
@include mediaMin($lg) {
    .category-tabs {
        margin-bottom: toRem(40);
        .btn {
            font-size: toRem(20);
            width: toRem(220);
        }
    }
}
</style>
