<template>
    <section class="tab3-container">
        <!-- Courses Listing -->
        <div class="listing-container">
            <div class="section-header">
                <SectionTitle
                    class="section-title"
                    :icon="courseImg"
                    :title="$t('courses')"
                    :total="categoryCoursesTotal"
                />
                <!-- TODO: Hide for now until a business requirement is added -->
                <!-- <Sort :isCategory="false" /> -->
            </div>
            <div class="listing-courses-grid" v-if="categoryCourses.length">
                <div v-for="course in categoryCourses" :key="course.id">
                    <CourseCard
                        :id="course.id"
                        :courseTitle="course?.name"
                        :logo="
                            category === 'university'
                                ? course?.includes?.university?.logo
                                : course?.includes?.institute?.logo
                        "
                        :categoryID="
                            category === 'university'
                                ? course?.includes?.university?.id
                                : course?.includes?.institute?.id
                        "
                        :categoryTitle="
                            category === 'university'
                                ? course?.includes?.university?.name
                                : course?.includes?.institute?.name
                        "
                        :categoryLogo="
                            category === 'university'
                                ? course?.includes?.university?.logo
                                : course?.includes?.institute?.logo
                        "
                        :location="`${course?.includes?.country?.name}, ${course?.includes?.city?.name || ''}`"
                        :fees="course?.fees"
                        :currency="course?.includes?.currency?.name"
                        :date="
                            category === 'university'
                                ? course?.includes?.dates.map(arr => arr.months.join(', ')).join(', ')
                                : ''
                        "
                        :favourite="course?.is_favorite"
                        :level="course?.includes?.level?.name"
                        :requirements="course?.requirements"
                    />
                </div>
            </div>
            <NoDataFound v-else />
            <div v-if="CourseLoading" class="loader-container">
                <ProgressSpinner
                    style="width: 50px; height: 50px"
                    strokeWidth="8"
                    fill="transparent"
                    animationDuration=".5s"
                />
            </div>
        </div>
    </section>
</template>

<script setup>
import courseImg from '../../../../libs/shared/assets/images/search/course_cap_3.png';
import CourseCard from '../common/CourseCard.vue';
import SectionTitle from '../common/SectionTitle.vue';
import Sort from '../common/Sort.vue';
import { useCheckCategory } from '../../../composables/useCheckCategory';
import { useCommonStore } from '../../../store/search/common';
import NoDataFound from '../common/NoDataFound.vue';

const { categoryCoursesTotal, categoryCourses, CourseLoading, CourseCurrentPage, courseLastPage, getCategoryCourses } =
    useCheckCategory();
const commonStore = useCommonStore();
const { category } = storeToRefs(commonStore);

// Detect when the user scrolls to the bottom
const onScroll = () => {
    // Start loading 500px before bottom
    const scrollBuffer = 500;
    const nearBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - scrollBuffer;

    if (nearBottom && !CourseLoading.value && CourseCurrentPage.value < courseLastPage.value) {
        getCategoryCourses.value(CourseCurrentPage.value + 1, true);
    }
};

// Set up the scroll listener
onMounted(() => {
    window.addEventListener('scroll', onScroll);
});

// Clean up the scroll listener when the component is destroyed
onBeforeUnmount(() => {
    window.removeEventListener('scroll', onScroll);
});
</script>

<style lang="scss" scoped>
@import 'libs/shared/assets/scss/main.scss';
.tab3-container {
    .listing-container {
        .section-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: toRem(24);
            .section-title {
                margin: 0;
            }
        }
        .loader-container {
            margin-top: 3rem;
            display: flex;
            justify-content: center;
        }
    }
}
@include mediaMin($lg) {
    .tab3-container {
        .listing-container {
            .section-header {
                margin-bottom: toRem(40);
            }
        }
        .filter-btn-responsive {
            display: none;
        }
    }
}
.listing-courses-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 23.5%);
    gap: toRem(16);

    @include respond-to(laptop) {
        grid-template-columns: repeat(auto-fill, 32%);
    }

    @include respond-to(small-laptop) {
        grid-template-columns: repeat(auto-fill, 48%);
    }

    @include respond-to(small-tablet) {
        grid-template-columns: repeat(auto-fill, 100%);
    }

    @include respond-to(large-mobile) {
        grid-template-columns: repeat(auto-fill, 100%);
    }
}
</style>
